import "./App.scss";
import { useEffect, useState, createRef, useLayoutEffect } from "react";
import LocomotiveScroll from "locomotive-scroll";

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

function App() {
  const [width, height] = useWindowSize();

  const scrollRef = createRef();

  useEffect(() => {
    if (width > 678) {
      const scroll = new LocomotiveScroll({
        el: scrollRef.current,
        smooth: true,
        direction: "horizontal",
      });
    }
  }, [width]);

  const arr = [...Array(63)].map((v, i) => i + 1);

  return (
    <>
      <div className="App">
        <div className="title">
          <p>lemmievandenberg@gmail.com</p>
        </div>

        <div className="scroll" ref={scrollRef} data-scroll-container>
          <div className="row" data-scroll-section>
            {arr
              .filter((x) => x % 2 === 0)
              .map((item, index) => (
                <div className="column" key={index}>
                  <div>
                    <img src={require(`./assets/${item}.jpg`)} />
                  </div>
                  <div>
                    <img src={require(`./assets/${item + 1}.jpg`)} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="App-sm">
        <div className="title">
          <p>lemmievandenberg@gmail.com</p>
        </div>
        <div>
          {arr.slice(1).map((item, index) => (
            <div key={index}>
              <img src={require(`./assets/${item}.jpg`)} />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default App;
